<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="设备编号" prop="deviceId">
                <a-input v-model="queryParam.deviceId" placeholder="请输入设备编号" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="工号" prop="jobNumber">
                <a-input v-model="queryParam.jobNumber" placeholder="请输入工号" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="汇总日期">
                  <a-range-picker
                    style="width: 100%"
                    v-model="daterangeReportDate"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <!-- <a-col :md="8" :sm="24">
                <a-form-item label="上班打卡时间点" prop="toWorkTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.toWorkTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="下班打卡时间点" prop="offWorkTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.offWorkTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear />
                </a-form-item>
              </a-col> -->
              <a-col :md="8" :sm="24">
                <a-form-item label="时长不低于(秒)" prop="timeSum">
                  <a-input v-model="beginTimeSum" placeholder="请输入时长(秒)" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="里程不低于(米)" prop="odoSum">
                  <a-input v-model="beginOdoSum" placeholder="请输入里程(米)" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="isRunReport = true"><a-icon type="play-circle" />生成日报 </a-button>
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd()"
            v-hasPermi="['tenant:jt808:employeeDailyReport:add']"
          >
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:jt808:employeeDailyReport:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <!-- <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tenant:jt808:employeeDailyReport:remove']"> <a-icon type="delete" />删除 </a-button> -->
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:jt808:employeeDailyReport:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="reportDate" slot-scope="text, record">
          {{ parseTime(record.reportDate) }}
        </span>
        <span slot="toWorkTime" slot-scope="text, record">
          {{ parseTime(record.toWorkTime) }}
        </span>
        <span slot="offWorkTime" slot-scope="text, record">
          {{ parseTime(record.offWorkTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:jt808:employeeDailyReport:edit']" />
          <a
            @click="$refs.createForm.handleUpdate(record, undefined)"
            v-hasPermi="['tenant:jt808:employeeDailyReport:edit']"
          >
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:jt808:employeeDailyReport:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:jt808:employeeDailyReport:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <a-modal
      class="daily-report"
      title="手动生成日报"
      :visible="isRunReport"
      @ok="handleRunReportOk"
      @cancel="isRunReport = false"
    >
      <a-form-model-item label="统计日期">
        <a-date-picker v-model="runDate" format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
      </a-form-model-item>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import {
  listEmployeeDailyReport,
  delEmployeeDailyReport,
  exportEmployeeDailyReport,
  runEmployeeDailyReport
} from '@/api/jt808/employeeDailyReport'
import CreateForm from './modules/CreateForm'
// import { exportExcel } from '@/utils/ruoyi'
import moment from 'moment'
export default {
  name: 'EmployeeDailyReport',
  components: {
    CreateForm
  },
  data() {
    return {
      title: '工牌在线日报',
      isRunReport: false,
      runDate: null,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      beginTimeSum: '',
      beginOdoSum: '',
      // 汇总日期时间范围
      daterangeReportDate: [],
      // 查询参数
      queryParam: {
        deviceId: null,
        jobNumber: null,
        reportDate: null,
        toWorkTime: null,
        offWorkTime: null,
        timeSum: null,
        odoSum: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '设备编号',
          dataIndex: 'deviceId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '工号',
          dataIndex: 'jobNumber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '姓名',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '汇总日期',
          dataIndex: 'reportDate',
          scopedSlots: { customRender: 'reportDate' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '上班打卡时间点',
          dataIndex: 'toWorkTime',
          scopedSlots: { customRender: 'toWorkTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '下班打卡时间点',
          dataIndex: 'offWorkTime',
          scopedSlots: { customRender: 'offWorkTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '时长(秒)',
          dataIndex: 'timeSum',
          customRender: (text, record, index) => {
            const time = moment.duration(record.timeSum, 'seconds')
            const hours = time.hours()
            const minutes = time.minutes()
            const seconds = time.seconds()
            return moment({ h: hours, m: minutes, s: seconds }).format('HH:mm:ss')
          },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '里程(米)',
          dataIndex: 'odoSum',
          ellipsis: true,
          align: 'center'
        }
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   width: '18%',
        //   scopedSlots: { customRender: 'operation' },
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询人员工牌在线日报列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeReportDate !== null &&
        this.daterangeReportDate !== '' &&
        this.daterangeReportDate.length !== 0
      ) {
        this.queryParam.params['beginReportDate'] = this.daterangeReportDate[0]
        this.queryParam.params['endReportDate'] = this.daterangeReportDate[1]
      }
      if (this.beginTimeSum) {
        this.queryParam.params['beginTimeSum'] = this.beginTimeSum
      }
      if (this.beginOdoSum) {
        this.queryParam.params['beginOdoSum'] = this.beginOdoSum
      }
      listEmployeeDailyReport(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeReportDate = []
      this.beginTimeSum = undefined
      this.beginOdoSum = undefined
      this.queryParam = {
        deviceId: undefined,
        jobNumber: undefined,
        toWorkTime: undefined,
        offWorkTime: undefined,
        timeSum: undefined,
        odoSum: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delEmployeeDailyReport(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportEmployeeDailyReport(that.queryParam).then((response) => {
            // exportExcel(response, that.title)
            // that.$message.success('导出成功', 3)
            const fileName = that.title // response.headers['download-filename']
            that.download(fileName, 'excel', response.data)
            // that.download(response.msg, null, null, process.env.VUE_APP_HTTP_RC_ENDPOINT)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    handleRunReportOk() {
      if (!this.runDate) {
        this.$warningEx('请选择统计日期')
      }
      const query = {
        date: this.runDate
      }
      this.loading = true
      runEmployeeDailyReport(query).then((response) => {
        if (response.code === 200) {
          this.daterangeReportDate.length = 0
          this.daterangeReportDate.push(this.runDate)
          this.daterangeReportDate.push(this.runDate)
          this.getList()
          this.isRunReport = false
          this.loading = false
        }
      })
    }
  }
}
</script>
<style lang="less">
.daily-report {
  .ant-form-item {
    display: flex;
  }
}
</style>
